import { Card, Descriptions, Flex, Table, TableProps, Tag } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { PaginatedData } from '../../../../../types/dto/common.dto';
import { ProgramDto } from '../../../../../types/dto/event.dto';
import { SubjectDto } from '../../../../../types/dto/subject.dto';
import { UserDto } from '../../../../../types/dto/user.dto';
import { getTagClassName } from '../../../../../utils/get-tag-class-name';

interface EnrolmentListProps {
  user: UserDto;
}

const EnrolmentList = (props: EnrolmentListProps) => {
  const { t } = useTranslation(['user', 'programs']);
  const { language, currentAcademicYear } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  const [subjects, setSubjects] = useState<PaginatedData<SubjectDto[]>>();

  const fetchPrograms = useCallback(async () => {
    setLoading(true);
    const response = await Server.Programs.getStudentPrograms(
      props.user.pin,
      currentAcademicYear
    );

    setPrograms(response);
    setLoading(false);
  }, [currentAcademicYear, props.user.pin]);

  const fetchSubjects = useCallback(async () => {
    setLoading(true);
    const response = await Server.Subjects.getSubjects({
      studentPin: props.user.pin,
      academicYear: currentAcademicYear,
    });

    setSubjects(response);
    setLoading(false);
  }, [currentAcademicYear, props.user.pin]);

  useEffect(() => {
    fetchPrograms();
    fetchSubjects();
  }, [fetchPrograms, fetchSubjects]);

  const columns: TableProps<SubjectDto>['columns'] = [
    {
      key: 'subjectName',
      title: t('user:enrolment_list.subject_name'),
      dataIndex: 'subjectName',
      width: '50%',
      render: (text, record) => (
        <span>
          {language === 'en'
            ? record.subjectNameEn ?? record.subjectName
            : record.subjectName}
        </span>
      ),
    },
    {
      key: 'lecturers',
      title: t('user:enrolment_list.lecturers'),
      dataIndex: 'lecturers',
      render: (lecturers) => {
        return lecturers?.map((lecturer: UserDto, index: number) => {
          const isLast = index === lecturers.length - 1;
          return (
            <span key={lecturer.pin}>
              <Link
                to={`/users/${lecturer.pin}`}
              >{`${lecturer.givenName} ${lecturer.familyName}`}</Link>
              <span>{!isLast ? ', ' : ''}</span>
            </span>
          );
        });
      },
    },
  ];

  return (
    <Card loading={loading}>
      {programs && programs.length > 0 ? (
        <>
          {programs.map((program) => (
            <>
              <Descriptions
                column={1}
                bordered
                size='small'
                labelStyle={{ width: '50%' }}
              >
                <Descriptions.Item label={t('user:enrolment_list.title')}>
                  {program.programName}
                </Descriptions.Item>
                <Descriptions.Item label={t('programs:columns.level')}>
                  <Tag className={getTagClassName(program.level)}>
                    {t(`programs:levels.${program.level}`)}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label={t('programs:columns.mode')}>
                  <Tag className={getTagClassName(program.mode)}>
                    {t(`programs:modes.${program.mode}`)}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label={t('programs:columns.location')}>
                  <Flex>
                    <Tag>{t(`programs:locations.${program.location}`)}</Tag>
                  </Flex>
                </Descriptions.Item>
              </Descriptions>
            </>
          ))}
          <h4 className='mt-2 mb-0'>{t('user:enrolment_list.subjects')}</h4>
          <Table
            size='small'
            className='mt-05'
            rowKey={(val) => val.id}
            columns={columns}
            dataSource={subjects?.records}
            pagination={{ hideOnSinglePage: true, total: subjects?.totalCount }}
          />
        </>
      ) : (
        <span>{t('enrolment_list.no_programs')}</span>
      )}
    </Card>
  );
};

export default EnrolmentList;
