import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { WorksheetDto } from '../../../../../types/dto/worksheet.dto';

interface Props {
  worksheet: WorksheetDto;
}

export function CompanyInfo({ worksheet }: Props) {
  const { t } = useTranslation('pdf');
  const styles = StyleSheet.create({
    container: { flexDirection: 'column' },
    bold: { fontWeight: 'bold' },
  });
  return (
    <View style={[styles.container, styles.bold]}>
      <Text>{t('company_info.university')} – Split</Text>
      <Text>Domovinskog rata 65, 21000 Split</Text>
      <Text>info@aspira.hr</Text>
    </View>
  );
}
