import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { GetPartnerDto, PartnerDto } from '../../types/dto/partner.dto';

export class Partner {
  async getPartners(
    getPartnersDto: GetPartnerDto
  ): Promise<PaginatedData<PartnerDto[]>> {
    const { page, pageSize } = getPartnersDto;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/partner/list`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getPartner(lecturerPin: string): Promise<PartnerDto> {
    const params = new URLSearchParams();
    params.append('lecturerPin', lecturerPin);
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/partner`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getPartnerByPin(partnerPin: string): Promise<PartnerDto> {
    const params = new URLSearchParams();
    params.append('partnerPin', partnerPin);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/partner/pin`,
      responseType: 'json',
      params,
    });

    return response.data as PartnerDto;
  }

  async createPartner(createPartnerDto: PartnerDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/partner`,
      responseType: 'json',
      data: createPartnerDto,
    });
  }

  async assignPartner(lecturerPin: string, createPartnerDto: PartnerDto) {
    const params = new URLSearchParams();
    params.append('lecturerPin', lecturerPin);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/partner/assign`,
      responseType: 'json',
      data: createPartnerDto,
      params,
    });
  }

  async updatePartner(partnerPin: string, updatePartnerDto: PartnerDto) {
    const params = new URLSearchParams();
    params.append('partnerPin', partnerPin);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/partner`,
      responseType: 'json',
      data: updatePartnerDto,
      params,
    });
  }
}
