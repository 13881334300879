import { PrinterOutlined } from '@ant-design/icons';
import { Button, Flex, QRCode } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { EventCheckInCodeDto } from '../../../../types/dto/event-check-in.dto';
import { EventDto } from '../../../../types/dto/event.dto';
import { handlePrint } from '../../../../utils/print.utils';

interface Props {
  checkInData: {
    event: EventDto;
    qrData: EventCheckInCodeDto;
  };
}

const EventQRCode = ({ checkInData }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Flex vertical>
      <div className='desktop-qr'>
        <div id='qr-print'>
          <Flex vertical className='mb-1'>
            <h2 className='m-0'>{checkInData.event.subjectName}</h2>
            <span className='text-gray'>{`${dayjs(
              checkInData.event.dateStart
            ).format('DD.MM.YYYY.')} ${dayjs(
              checkInData.event.dateStart
            ).format('HH:mm')} - ${dayjs(checkInData.event.dateEnd).format(
              'HH:mm'
            )}`}</span>
            <span className='text-gray'>{checkInData.event.classroom}</span>
          </Flex>
          <QRCode
            value={checkInData?.qrData.checkInUrl}
            type='svg'
            size={520}
          />
        </div>
        <h2 className='text-center'>{checkInData.qrData.checkInUrl}</h2>
      </div>

      <div className='mobile-qr'>
        <Flex vertical className='mb-1'>
          <h2 className='m-0'>{checkInData.event.subjectName}</h2>
          <span className='text-gray'>{`${dayjs(
            checkInData.event.dateStart
          ).format('DD.MM.YYYY.')} ${dayjs(checkInData.event.dateStart).format(
            'HH:mm'
          )} - ${dayjs(checkInData.event.dateEnd).format('HH:mm')}`}</span>
          <span className='text-gray'>{checkInData.event.classroom}</span>
        </Flex>
        <QRCode value={checkInData?.qrData.checkInUrl} type='svg' size={320} />
        <h3 className='text-center'>{checkInData.qrData.checkInUrl}</h3>
      </div>

      <Flex className='print-qr-button' justify='flex-end'>
        <Button
          type='primary'
          ghost
          icon={<PrinterOutlined />}
          onClick={() => handlePrint('qr-print', 'QR print')}
        >
          {t('common:print')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default EventQRCode;
