export const handlePrint = (elementId: string, title: string) => {
  const printableContent = document.getElementById(elementId);
  if (printableContent) {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      const printDocument = printWindow.document;

      const htmlContent = printableContent.outerHTML;
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join('\n');
          } catch (e) {
            console.log('Error while reading CSS rules from stylesheet:', e);
            return '';
          }
        })
        .join('\n');

      printDocument.write(`
        <html>
          <head>
            <title>${title}</title>
            <style>
              ${styles}
              body {
                font-family: 'Arial', sans-serif; 
              }
            </style>
          </head>
          <body>
            ${htmlContent}
          </body>
        </html>
      `);
      printDocument.close();

      printWindow.focus();
      printWindow.print();
    }
  }
};
