import { Flex, Modal, notification, Select } from 'antd';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../../api/server-index';
import { UserDto } from '../../../../../../types/dto/user.dto';

interface AssignProgramToCoordinatorProps {
  isModalOpen: boolean;
  coordinator: UserDto;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

interface ProgramOption {
  value: string;
  label: ReactNode;
}

const AssignProgramToCoordinator = (props: AssignProgramToCoordinatorProps) => {
  const { isModalOpen, setIsModalOpen, setReload, coordinator } = props;
  const { t } = useTranslation('programs');
  const [programOptions, setProgramOptions] = useState<ProgramOption[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);

  const fetchProgramOptions = useCallback(async () => {
    const alreadyCoordinatedPrograms = coordinator.coordinatedPrograms?.map(
      (program) => program.id
    );
    let response = await Server.Programs.getProgramsFlat();

    // filter out already coordinated programs
    if (alreadyCoordinatedPrograms) {
      response = response?.filter(
        (program) => !alreadyCoordinatedPrograms.includes(program.id)
      );
    }

    const options = response?.map((program) => {
      const programName = program.programName;
      const programDetails = `${t(`levels.${program.level}`)} - ${t(
        `modes.${program.mode}`
      )} - ${t(`locations.${program.location}`)}`;

      return {
        value: program.id,
        label: (
          <Flex vertical>
            <span>{programName}</span>
            <small className='text-gray'>
              <i>{programDetails}</i>
            </small>
          </Flex>
        ),
      };
    });

    setProgramOptions(options);
  }, [coordinator.coordinatedPrograms, t]);

  useEffect(() => {
    fetchProgramOptions();
  }, [fetchProgramOptions]);

  const filterOption = (input: string, option: any) => {
    if (option) {
      const programName = option?.label.props.children[0].props.children || '';
      return programName.toLowerCase().includes(input.toLowerCase());
    }
    return false;
  };

  const assignProgramsToCoordinator = async () => {
    const response = await Server.Programs.assignProgramsToCoordinator({
      coordinatorPin: coordinator.pin,
      programIds: selectedPrograms,
    });

    if (response.status === 201) {
      notification.success({ message: t('coordinator.assign.success') });
      setReload(true);
    }
  };

  return (
    <Modal
      destroyOnClose
      open={isModalOpen}
      title={t('coordinator.assign.title')}
      onOk={() => {
        assignProgramsToCoordinator();
        setIsModalOpen(false);
      }}
      okButtonProps={{ ghost: true }}
      onCancel={() => setIsModalOpen(false)}
      okText={t('common:save')}
      cancelText={t('common:cancel')}
    >
      <Flex vertical>
        <p>{t('coordinator.assign.description')}</p>
        <Select
          showSearch
          mode='multiple'
          placeholder={t('coordinator.assign.programs')}
          options={programOptions}
          filterOption={filterOption}
          onChange={(value: string[]) => setSelectedPrograms(value)}
        />
      </Flex>
    </Modal>
  );
};

export default AssignProgramToCoordinator;
