import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import {
  AnnualLeaveRequestDto,
  CreateAnnualLeaveRequestDto,
  GetAnnualLeaveRequestsDto,
} from '../../types/dto/work-logs.dto';

export class AnnualLeaveRequests {
  async getAnnualLeaveRequests(
    employeePin: string,
    getAnnualLeaveRequests: GetAnnualLeaveRequestsDto
  ): Promise<PaginatedData<AnnualLeaveRequestDto[]>> {
    const { page, pageSize, status, location } = getAnnualLeaveRequests;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('employeePin', employeePin);

    if (status) {
      status.forEach((s) => {
        params.append('status[]', s);
      });
    }

    if (location) {
      params.append('location', location);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/annual-leave-request`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createAnnualLeaveRequest(createRequest: CreateAnnualLeaveRequestDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/annual-leave-request`,
      responseType: 'json',
      data: createRequest,
    });
  }

  async deleteAnnualLeaveRequest(id: string) {
    const params = new URLSearchParams();
    params.append('requestId', id);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/annual-leave-request`,
      responseType: 'json',
      params,
    });
  }

  async approveAnnualLeaveRequest(id: string) {
    const params = new URLSearchParams();
    params.append('requestId', id);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/annual-leave-request/approve`,
      responseType: 'json',
      params,
    });
  }

  async rejectAnnualLeaveRequest(id: string) {
    const params = new URLSearchParams();
    params.append('requestId', id);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/annual-leave-request/reject`,
      responseType: 'json',
      params,
    });
  }
}
