import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../api/server-index';
import { PartnerDto } from '../../../../types/dto/partner.dto';
import { UserDto } from '../../../../types/dto/user.dto';
import {
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
} from '../../../../types/dto/worksheet.dto';

interface Props {
  worksheet: WorksheetDto;
  worksheetEvents: WorksheetEventDto[];
  otherExpenses: OtherExpensesDto[];
  user?: UserDto;
}

export function WorkSheetPdfDisplay({
  worksheet,
  worksheetEvents,
  otherExpenses,
  user,
}: Props) {
  const { t } = useTranslation(['pdf', 'worksheets']);
  const [partner, setPartner] = useState<PartnerDto>();

  useEffect(() => {
    const fetchPartner = async () => {
      if (user) {
        const response = await Server.Partner.getPartner(user.pin);
        setPartner(response);
      }
    };

    fetchPartner();
  }, [user]);

  return (
    <>
      <div className='worksheet-wrapper'>
        <div className='worksheet-header'>
          <div className='company-credentials'>
            <img
              src={'/assets/aspira-uciliste.png'}
              alt='Logo'
              className='img-fluid mw-100'
            />
            <div>{t('company_info.university')} – Split</div>
            <div>Domovinskog rata 65, 21000 Split</div>
            <div>info@aspira.hr</div>
          </div>
          <div className='partner-credentials'>
            {`${user?.givenName} ${user?.familyName}`}
            <div>{user?.email}</div>
            {partner ? (
              <>
                {t('pdf:invoice_to.partner')}: {partner?.name}{' '}
                {t(`partner:types.${partner?.type}`)}
                <div>{partner?.iban?.toUpperCase()}</div>{' '}
                <div>{partner?.intlBankSwift}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='worksheet-body'>
          <div className='service-container'>
            <div className='service-title text-center'>
              {t('table.recapitulation')}: {t('table.worksheet')} #
              {worksheet.worksheetNr}
            </div>
            <div className='service-subtitle text-center'>
              {`${dayjs(worksheet.startDate).format('DD.MM.YYYY.')} - ${dayjs(
                worksheet.endDate
              ).format('DD.MM.YYYY.')}`}
            </div>
            {worksheetEvents.length > 0 ? (
              <div className='table-top-row'>
                <div className='table-top-row-type'>
                  {t('table.top_row.type')}
                </div>
                <div className='table-top-row-period'>
                  {t('table.top_row.period')}
                </div>
                <div className='table-top-row-amount'>
                  {t('table.top_row.amount')}
                </div>
              </div>
            ) : (
              <></>
            )}
            {worksheetEvents.map((event) => {
              return (
                <div key={event.eventId} className='service-item'>
                  <div className='service-name'>
                    {t(
                      `worksheets:edit.events.event_type.${event.eventType}`
                    ).toUpperCase()}
                  </div>
                  <div className='service-hours'>{`${dayjs(
                    event.dateStart
                  ).format('DD.MM.YYYY.')} (${dayjs(event.dateStart).format(
                    'HH:mm'
                  )} - ${dayjs(event.dateEnd).format('HH:mm')})`}</div>
                  <div className='service-price'>
                    {event.totalAmount?.toFixed(2).replace('.', ',')} €
                  </div>
                </div>
              );
            })}
            {otherExpenses.length > 0 ? (
              <div className='table-top-row'>
                <div className='table-top-row-type'>
                  {t('table.top_row.other_expense')}
                </div>
                <div className='table-top-row-amount'>
                  {t('table.top_row.amount')}
                </div>
              </div>
            ) : (
              <></>
            )}
            {otherExpenses?.map((expense) => {
              return (
                <div key={expense.id} className='service-item'>
                  <div className='service-name'>{expense.title}</div>
                  <div className='service-price'>
                    {expense.totalAmount?.toFixed(2).replace('.', ',')} €
                  </div>
                </div>
              );
            })}
            <div className='service-item total'>
              <div className='service-name'>
                {t('table.footer.total').toUpperCase()}
              </div>
              <div className='service-price'>
                {Number.parseFloat(worksheet.totalAmount?.toString())
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </div>
            </div>
          </div>
          <div className='note-row'>{t('note')}</div>
        </div>
        <div className='worksheet-footer'>
          <p className='copyright'>{t('copyright')}: Aspira SEPI © 2024</p>
        </div>
      </div>
    </>
  );
}
