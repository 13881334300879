import { Spin, Table, TableProps, Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { PartnerDto } from '../../../types/dto/partner.dto';
import { setNavigationPath } from '../../../utils/navigation-params';
import NewButton from '../../common/buttons/new-button.component';
import MainTitle from '../../common/titles/main-title.component';

const Partners = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('partner');
  const [partners, setPartners] = useState<PaginatedData<PartnerDto[]>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });

  const fetchPartners = useCallback(async () => {
    setLoading(true);
    const response = await Server.Partner.getPartners({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
    });

    setPartners(response);
    setLoading(false);
  }, [dataSelection]);

  useEffect(() => {
    fetchPartners();
    setSearchParams(setNavigationPath({}, dataSelection));
    setReload(false);
  }, [reload, dataSelection, setSearchParams, fetchPartners]);

  const columns: TableProps<PartnerDto>['columns'] = [
    {
      key: 'pin',
      title: t('form.pin'),
      dataIndex: 'pin',
      width: '20%',
      render: (pin) => (
        <Link to={`/partners/edit/${pin}?${searchParams.toString()}`}>
          {pin}
        </Link>
      ),
    },
    {
      key: 'name',
      title: t('form.name'),
      dataIndex: 'name',
      width: '60%',
      render: (name) => <span>{name}</span>,
    },
    {
      key: 'type',
      title: t('form.type'),
      dataIndex: 'type',
      width: '20%',
      render: (type) => <Tag>{t(`types.${type}`)}</Tag>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('partners')} />
      <NewButton onClick={() => navigate('new')} />
      <Table
        className='mt-1 mb-2'
        dataSource={partners?.records}
        columns={columns}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.pin}
        pagination={{
          hideOnSinglePage: true,
          total: partners?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
      />
    </Spin>
  );
};

export default Partners;
