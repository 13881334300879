import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { UserRoles } from '../../../../../types/enum/user-roles';
import BackButton from '../../../../common/buttons/back-button.component';
import Subtitle from '../../../../common/titles/subtitle.component';
import { formItemLayout } from '../../../../layout/form/form-item.layout';

const NewUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('user');
  const userOptionsArray = useRef(Object.values(UserRoles));

  const createNewUser = async (values: any) => {
    setLoading(true);
    const responseCreate = await Server.User.createUser({
      pin: values.pin,
      givenName: values.givenName,
      familyName: values.familyName,
      canonicalName: values.canonicalName,
      email: values.email,
      hrEduUid: values.hrEduUid,
      isEmployee: values.isEmployee,
    });

    const responseRole = await Server.User.updateUserRoles(
      values.pin,
      values.roles
    );

    if (responseCreate.status === 201 && responseRole.status === 200) {
      notification.success({ message: t('new.success_create') });
      navigate(-1);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BackButton href={`/users${location?.search}`} />
      <Subtitle text={t('new.title')} />
      <Form
        name='new-user'
        className='mt-1'
        onFinish={createNewUser}
        {...formItemLayout}
      >
        <Form.Item
          label={t('form.pin')}
          name='pin'
          rules={[
            { required: true, message: t('new.required') },
            {
              pattern: /^\d{11}$/,
              message: t('new.invalid_pin'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.email')}
          name='email'
          rules={[
            { required: true, message: t('new.required') },
            { type: 'email', message: t('new.invalid_email') },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.givenName')}
          name='givenName'
          rules={[{ required: true, message: t('new.required') }]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.familyName')}
          name='familyName'
          rules={[{ required: true, message: t('new.required') }]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('form.roles')}
          name='roles'
          rules={[{ required: true, message: t('new.required') }]}
          validateTrigger='onBlur'
        >
          <Select
            mode='multiple'
            options={userOptionsArray.current.map((option) => ({
              value: option,
              label: t(`roles.${option}`),
            }))}
          />
        </Form.Item>
        <Form.Item label={t('form.hrEduUid')} name='hrEduUid'>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.isEmployee')} name='isEmployee'>
          <Switch />
        </Form.Item>
        <Flex justify='flex-end'>
          <Form.Item>
            <Button loading={loading} htmlType='submit' type='primary' ghost>
              {t('new.submit')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};

export default NewUser;
