import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Card, Flex, Tooltip, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../api/server-index';
import MainTitle from '../../common/titles/main-title.component';

const Settings = () => {
  const { t } = useTranslation('settings');

  const sendChangePasswordEmail = async () => {
    const response = await Server.User.changePassword();

    if (response.status === 200) {
      notification.success({ message: t('password.change.success') });
    }
  };

  return (
    <div>
      <MainTitle text={t('title')} />
      <Card>
        <Flex gap={8}>
          <h4>{t('password.change.title')}</h4>
          <Tooltip title={t('password.change.tooltip')}>
            <QuestionCircleFilled />
          </Tooltip>
        </Flex>
        <Button onClick={() => sendChangePasswordEmail()} type='primary' ghost>
          {t('password.change.send')}
        </Button>
      </Card>
    </div>
  );
};

export default Settings;
