import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';

import MainTitle from '../../common/titles/main-title.component';
import AnnualLeaveRequests from './annual-leave-requests/annual-leave-requests.component';
import BusinessTripRequests from './business-trip-requests/business-trip-requests.component';
import WorkLogs from './work-logs/work-logs.outlet';

const WorkLogsAndRequests = () => {
  const { t } = useTranslation('work-logs');

  const tabItems: TabsProps['items'] = [
    { key: 'work-logs', label: t('work-logs'), children: <WorkLogs /> },
    {
      key: 'btr',
      label: t('business-trip-requests'),
      children: <BusinessTripRequests />,
    },
    {
      key: 'alr',
      label: t('annual-leave-requests'),
      children: <AnnualLeaveRequests />,
    },
  ];

  return (
    <>
      <MainTitle text={t('title')} />
      <Tabs
        defaultActiveKey='work-logs'
        items={tabItems}
        destroyInactiveTabPane
      />
    </>
  );
};
export default WorkLogsAndRequests;
