import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Flex, Modal, UploadProps, notification } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../../context/auth-context';
import { API_URL } from '../../../../router/constants/constants';
import { ImportEventsResponseDto } from '../../../../types/dto/event.dto';
import MainTitle from '../../../common/titles/main-title.component';

interface Props {
  isImportModalOpen: boolean;
  setIsImportModalOpen: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ImportEvents = (props: Props) => {
  const { isImportModalOpen, setIsImportModalOpen, setLoading, setReload } =
    props;
  const token = window.localStorage.getItem('authToken');
  const { t } = useTranslation('events');
  const { user } = useContext(AuthContext);
  const [importResponse, setImportResponse] =
    useState<ImportEventsResponseDto>();

  const handleChange: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    const { status, response } = info.file;

    if (status === 'uploading') {
      setLoading(true);
      return;
    }

    if (status === 'done') {
      notification.success({
        key: user?.pin,
        message: t('create_update_event.import.success'),
      });

      setLoading(false);
      setIsImportModalOpen(false);
      setReload(true);
    }

    if (status === 'error') {
      setImportResponse(response.data);
      notification.error({
        key: user?.pin,
        message: t('create_update_event.import.error'),
      });
      setLoading(false);
    }
    if (status === 'removed') {
      setImportResponse(undefined);
    }
  };

  const downloadXlsx = () => {
    const excelFilePath = 'assets/events_import_table.xlsx';
    const link = document.createElement('a');
    link.href = excelFilePath;
    link.download = 'events_import_table.xlsx';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      open={isImportModalOpen}
      onCancel={() => setIsImportModalOpen(false)}
      footer={false}
    >
      <MainTitle text={t('create_update_event.import.title')} />
      <Flex className='mb-1' gap={8}>
        <DownloadOutlined />
        <span className='download-link' onClick={downloadXlsx}>
          {t('create_update_event.import.download')}
        </span>
      </Flex>
      <Dragger
        accept='.xlsx, .xls'
        multiple={false}
        method='POST'
        action={`${API_URL}/api/admin/events/import-events`}
        headers={{ Authorization: `Bearer ${token}` }}
        onChange={handleChange}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>
          {t('create_update_event.import.instructions')}
        </p>
        <p className='ant-upload-hint'>
          {t('create_update_event.import.format')}
        </p>
      </Dragger>
      <Flex vertical className='mt-1 text-danger'>
        {importResponse ? (
          <span key={importResponse.index}>
            {t('create_update_event.import.message.title', {
              number: importResponse.index,
              reason: t(
                `create_update_event.import.message.${importResponse.reason}`
              ),
            })}
          </span>
        ) : (
          <></>
        )}
      </Flex>
    </Modal>
  );
};

export default ImportEvents;
