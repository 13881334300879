import { Button, Col, Flex, Input, Row } from 'antd';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchDataSelection } from '../../../../types/common/search-data-selection.type';

interface UsersFilterProps {
  setDataSelection: (value: SetStateAction<SearchDataSelection>) => void;
  dataSelection: SearchDataSelection;
}

const UsersFilter = (props: UsersFilterProps) => {
  const { t } = useTranslation('user');

  const handleResetFilter = () => {
    props.setDataSelection((prevVal) => ({
      ...prevVal,
      search: undefined,
    }));
  };

  return (
    <Row>
      <Col xs={24} md={12} lg={12}>
        <Flex gap={8}>
          <Input
            placeholder={t('search')}
            value={props.dataSelection.search}
            onChange={(event) => {
              props.setDataSelection((prevVal) => ({
                ...prevVal,
                page: 1,
                search: event.target.value,
              }));
            }}
          />
          <Button onClick={() => handleResetFilter()} danger ghost>
            {t('reset')}
          </Button>
        </Flex>
      </Col>
    </Row>
  );
};

export default UsersFilter;
