import {
  CalendarFilled,
  ContactsFilled,
  FolderFilled,
  IdcardFilled,
  ProfileFilled,
  QuestionCircleFilled,
  ReadFilled,
  SettingFilled,
} from '@ant-design/icons';

import Events from '../../components/pages/events';
import CheckInList from '../../components/pages/events/check-in-list/check-in-list.component';
import EditEvent from '../../components/pages/events/edit-event/edit-event.component';
import EventsOutlet from '../../components/pages/events/events.outlet';
import NewEvent from '../../components/pages/events/new-event/new-event.component';
import Partners from '../../components/pages/partners';
import EditPartner from '../../components/pages/partners/edit-partner/edit-partner.component';
import NewPartner from '../../components/pages/partners/new-partner/new-partner.component';
import PartnersOutlet from '../../components/pages/partners/partners.outlet';
import Programs from '../../components/pages/programs';
import ProgramStudents from '../../components/pages/programs/program-students/program-students.component';
import ProgramSubjects from '../../components/pages/programs/program-subjects/program-subjects.component';
import ProgramsOutlet from '../../components/pages/programs/programs.outlet';
import ChangePassword from '../../components/pages/settings/change-password.component';
import Settings from '../../components/pages/settings/settings.component';
import UserManual from '../../components/pages/user-manual/user-manual.component';
import { Users } from '../../components/pages/users';
import NewUser from '../../components/pages/users/user/new-user/new-user.component';
import { User } from '../../components/pages/users/user/user.component';
import { UsersOutlet } from '../../components/pages/users/users.outlet';
import WorkLogsAndRequests from '../../components/pages/work-logs-and-requests/work-logs-and-requests.component';
import Worksheets from '../../components/pages/worksheets';
import EditWorksheet from '../../components/pages/worksheets/edit-worksheet/edit-worksheet.component';
import WorksheetsOutlet from '../../components/pages/worksheets/worksheets.outlet';
import { UserDto } from '../../types/dto/user.dto';
import { UserRoles } from '../../types/enum/user-roles';
import { HasRoles } from '../../utils/has-roles';

export const RoutesConstants = (user?: UserDto) => {
  return [
    {
      path: '/events',
      element: <EventsOutlet />,
      icon: <CalendarFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'events',
      subroutes: [
        {
          path: '',
          element: <Events />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
        {
          path: 'new',
          element: <NewEvent />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
            user
          ),
        },
        {
          path: 'edit/:eventId',
          element: <EditEvent />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
            user
          ),
        },
        {
          path: 'checked-in/:eventId',
          element: <CheckInList />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
            user
          ),
        },
      ],
    },
    {
      path: '/worksheets',
      element: <WorksheetsOutlet />,
      icon: <FolderFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'worksheets',
      subroutes: [
        {
          path: '',
          element: <Worksheets />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
        {
          path: 'review/:worksheetId',
          element: <EditWorksheet />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
      ],
    },
    {
      path: '/users',
      element: <UsersOutlet />,
      icon: <ContactsFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'users',
      subroutes: [
        {
          path: '',
          element: <Users />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
        {
          path: 'new',
          element: <NewUser />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
            user
          ),
        },
        {
          path: ':userPin',
          element: (
            <>
              <User
                viewEditPartner={
                  HasRoles(
                    [UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR],
                    user
                  )!
                }
                editUser={HasRoles([UserRoles.ADMINISTRATOR], user)!}
              />
            </>
          ),
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
      ],
    },
    {
      path: '/programs',
      element: <ProgramsOutlet />,
      icon: <ReadFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'programs',
      subroutes: [
        {
          path: '',
          element: <Programs />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
        {
          path: 'subjects/:programId',
          element: <ProgramSubjects />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
        {
          path: 'students/:programId',
          element: <ProgramStudents />,
          visibleToUser: HasRoles(
            [
              UserRoles.ADMINISTRATOR,
              UserRoles.PROGRAMME_COORDINATOR,
              UserRoles.ACCOUNTING,
            ],
            user
          ),
        },
      ],
    },
    {
      path: '/work-logs-requests',
      element: <WorkLogsAndRequests />,
      icon: <ProfileFilled />,
      visibleToUser:
        HasRoles(
          [
            UserRoles.ADMINISTRATOR,
            UserRoles.ACCOUNTING,
            UserRoles.PROGRAMME_COORDINATOR,
          ],
          user
        ) && user?.isEmployee,
      sidebar: true,
      name: 'work-logs-requests',
    },
    {
      path: '/partners',
      element: <PartnersOutlet />,
      icon: <IdcardFilled />,
      visibleToUser: HasRoles(
        [UserRoles.ADMINISTRATOR, UserRoles.ACCOUNTING],
        user
      ),
      sidebar: true,
      name: 'partners',
      subroutes: [
        {
          path: '',
          element: <Partners />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.ACCOUNTING],
            user
          ),
        },
        {
          path: 'new',
          element: <NewPartner />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.ACCOUNTING],
            user
          ),
        },
        {
          path: 'edit/:partnerPin',
          element: <EditPartner />,
          visibleToUser: HasRoles(
            [UserRoles.ADMINISTRATOR, UserRoles.ACCOUNTING],
            user
          ),
        },
      ],
    },
    {
      path: '/settings',
      element: <Settings />,
      icon: <SettingFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'settings',
    },
    {
      path: '/change-password',
      element: <ChangePassword />,
      icon: <></>,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: false,
      name: 'change-password',
    },
    {
      path: '/manual',
      element: <UserManual />,
      icon: <QuestionCircleFilled />,
      visibleToUser: HasRoles(
        [
          UserRoles.ADMINISTRATOR,
          UserRoles.PROGRAMME_COORDINATOR,
          UserRoles.ACCOUNTING,
        ],
        user
      ),
      sidebar: true,
      name: 'user-manual',
    },
  ];
};
