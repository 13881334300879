import { StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { WorksheetDto } from '../../../../../../types/dto/worksheet.dto';
interface Props {
  worksheet: WorksheetDto;
}

export function TableHeader({ worksheet }: Props) {
  const { t } = useTranslation('pdf');
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    rowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    serviceTitle: { fontSize: '16', fontWeight: 'bold' },
    serviceSubtitle: { fontSize: '16', marginBottom: '10px' },
  });
  return (
    <View style={styles.container}>
      <View style={styles.rowCenter}>
        <Text style={styles.serviceTitle}>
          {t('table.recapitulation')}: {t('table.worksheet')} #
          {worksheet.worksheetNr}
        </Text>
      </View>
      <View style={styles.rowCenter}>
        <Text style={styles.serviceSubtitle}>{`${dayjs(
          worksheet.startDate
        ).format('DD.MM.YYYY.')} - ${dayjs(worksheet.endDate).format(
          'DD.MM.YYYY.'
        )}`}</Text>
      </View>
    </View>
  );
}
