import { View, StyleSheet } from '@react-pdf/renderer';

import {
  DailyWorkLogDto,
  MonthlyWorkLogDto,
} from '../../../../../../../types/dto/work-logs.dto';
import { ColumnType } from '../../../../../../../types/enum/wl-column-type';
import MonthlyWorkLogPDFTableHeader from './mwl-table-header';
import MonthlyWorkLogPDFTableRow from './mwl-table-row';

interface Props {
  columnType: ColumnType;
  monthlyWorkLog: MonthlyWorkLogDto;
  dailyWorkLogs: DailyWorkLogDto[];
}

const MonthlyWorkLogPDFTable = (props: Props) => {
  const styles = StyleSheet.create({
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

  return (
    <View style={styles.tableContainer}>
      <MonthlyWorkLogPDFTableHeader columnType={props.columnType} />
      {props.dailyWorkLogs.map((wl) => (
        <MonthlyWorkLogPDFTableRow
          key={wl.id}
          dailyWorkLog={wl}
          columnType={props.columnType}
          monthlyWorkLog={props.monthlyWorkLog}
        />
      ))}
    </View>
  );
};

export default MonthlyWorkLogPDFTable;
