import axios from 'axios';

import {
  API_URL,
  CAS_SERVER_URL,
  FRONTEND_URL,
} from '../../router/constants/constants';
import { Credentials } from '../../types/auth';

export class Auth {
  async login({ email, password }: Credentials): Promise<boolean> {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/api/auth`,
        responseType: 'json',
        data: {
          email,
          password,
        },
      });
      window.localStorage.setItem('authToken', response.data.authToken);
      return true;
    } catch (err) {
      return false;
    }
  }

  async verifyCasTicket(
    casTicket: string,
    intendedDestination?: string
  ): Promise<boolean> {
    try {
      const params = new URLSearchParams();
      params.append('cas-ticket', casTicket);
      if (intendedDestination) {
        params.append('intended-destination', intendedDestination);
      }

      const response = await axios({
        method: 'GET',
        url: `${API_URL}/api/auth/cas-ticket`,
        responseType: 'json',
        withCredentials: true,
        params,
      });

      window.localStorage.setItem('authToken', response.data.authToken);
      return true;
    } catch (err) {
      return false;
    }
  }

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  logoutWithAaiEdu() {
    const casLogoutUrl = `${CAS_SERVER_URL}/logout?url=${FRONTEND_URL}/login`;
    window.location.href = casLogoutUrl;
    window.localStorage.clear();
    window.sessionStorage.clear();
  }
}
