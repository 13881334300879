import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import {
  CompensationDto,
  CreateCompensationDto,
} from '../../types/dto/compensation.dto';

export class Compensation {
  async getCompensation(lecturerPin: string): Promise<CompensationDto[]> {
    const params = new URLSearchParams();
    params.append('lecturerPin', lecturerPin);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/compensation`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createCompensation(compensation: CreateCompensationDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/compensation`,
      responseType: 'json',
      data: compensation,
    });
  }

  async updateCompensation(compensationId: string, compensation: number) {
    const params = new URLSearchParams();
    params.append('compensationId', compensationId);
    params.append('compensation', compensation.toString());

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/compensation`,
      responseType: 'json',
      params,
    });
  }

  async deleteCompensation(compensationId: string) {
    const params = new URLSearchParams();
    params.append('compensationId', compensationId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/compensation`,
      responseType: 'json',
      params,
    });
  }
}
