import {
  DeleteOutlined,
  EditOutlined,
  QrcodeOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Flex,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Table,
  TableProps,
  Tooltip,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { AuthContext } from '../../../context/auth-context';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { EventCheckInCodeDto } from '../../../types/dto/event-check-in.dto';
import { EventDto } from '../../../types/dto/event.dto';
import { UserDto } from '../../../types/dto/user.dto';
import { Location } from '../../../types/enum/location';
import { UserRoles } from '../../../types/enum/user-roles';
import { HasRoles } from '../../../utils/has-roles';
import { setNavigationPath } from '../../../utils/navigation-params';
import ImportButton from '../../common/buttons/import-button.component';
import NewButton from '../../common/buttons/new-button.component';
import FilterIcon from '../../common/icons/filter-icon.component';
import LecturerSelect from '../../common/select/lecturer-select.component';
import MainTitle from '../../common/titles/main-title.component';
import ImportEvents from './import-events/import-events.component';
import EventQRCode from './qr-code/event-qr-code.component';

const Events = () => {
  const { t } = useTranslation('events');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [filter, setFilter] = useState<{
    date: string;
    dateTo: string;
    lecturerPin?: string;
    location?: Location;
  }>({
    date:
      searchParams.get('date') ?? dayjs().startOf('day').format('DD-MM-YYYY'),
    dateTo:
      searchParams.get('dateTo') ??
      dayjs().add(1, 'month').format('DD-MM-YYYY'),
    lecturerPin: searchParams.get('lecturerPin') ?? '',
    location: (searchParams.get('location') as Location) ?? undefined,
  });

  const [events, setEvents] = useState<PaginatedData<EventDto[]>>();
  const [checkInData, setCheckInData] = useState<{
    event: EventDto;
    qrData: EventCheckInCodeDto;
  }>();

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    const response = await Server.Events.getEvents(
      dayjs(filter.date, 'DD-MM-YYYY').toISOString(),
      dayjs(filter.dateTo, 'DD-MM-YYYY').toISOString(),
      dataSelection.page,
      dataSelection.pageSize,
      filter.lecturerPin,
      filter.location
    );

    setEvents(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchEvents();
    setSearchParams(setNavigationPath(filter, dataSelection));
    setReload(false);
  }, [dataSelection, fetchEvents, filter, setSearchParams, reload]);

  const handleDeleteEvent = async (eventId: string) => {
    const response = await Server.Events.deleteEvent(eventId);

    if (response.status === 200) {
      notification.success({ message: t('event_list.delete.success') });
      setReload(true);
    }
  };

  const getQRcode = async (record: EventDto) => {
    try {
      const response = await Server.EventCheckIn.getCheckInCode(record.id);
      setCheckInData({ event: record, qrData: response });
      setIsModalOpen(true);
    } catch (error) {}
  };

  const columns: TableProps<EventDto>['columns'] = [
    {
      key: 'date',
      title: t('event_list.columns.date_and_time'),
      dataIndex: 'id',
      width: '15%',
      filterDropdown: () => (
        <Flex gap={8} className='p-1'>
          <Flex align='center' gap={6}>
            {t('event_list.filter.from')}:
            <DatePicker
              format={'DD.MM.YYYY.'}
              value={dayjs(filter.date, 'DD-MM-YYYY')}
              onChange={(date) => {
                if (date) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    date: date.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
          </Flex>
          <Flex align='center' gap={6}>
            {t('event_list.filter.to')}:
            <DatePicker
              format={'DD.MM.YYYY.'}
              value={dayjs(filter.dateTo, 'DD-MM-YYYY')}
              onChange={(dateTo) => {
                if (dateTo) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    dateTo: dateTo.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
          </Flex>
        </Flex>
      ),
      filterIcon: <FilterIcon count={2} />,
      render: (id, record) => (
        <>
          <p className='m-0'>{`${dayjs(record.dateStart).format(
            'DD.MM.YYYY.'
          )}`}</p>
          <p className='m-0 text-gray'>{`${dayjs(record.dateStart).format(
            'HH:mm'
          )} - ${dayjs(record.dateEnd).format('HH:mm')}`}</p>
        </>
      ),
    },
    {
      key: 'subjectName',
      title: t('event_list.columns.subject_name'),
      dataIndex: 'subjectName',
      width: '20%',
      render: (text, record) => (
        <Flex vertical>
          <h4 className='m-0'>{text}</h4>
          {record.notes ? (
            <small className='text-gray'>{record.notes}</small>
          ) : (
            <></>
          )}
        </Flex>
      ),
    },
    {
      key: 'lecturers',
      title: t('event_list.columns.lecturers'),
      dataIndex: 'lecturers',
      width: '20%',
      filterDropdown: () => (
        <LecturerSelect
          selectedLecturerPin={filter.lecturerPin}
          className='filter-select'
          placeholder={t('event_list.filter.lecturer')}
          showSearch={true}
          onChange={(value) =>
            setFilter((prevFilterData) => ({
              ...prevFilterData,
              lecturerPin: value,
            }))
          }
        />
      ),
      filterIcon: () => <FilterIcon count={filter.lecturerPin ? 1 : 0} />,
      render: (lecturers) =>
        lecturers.map((lecturer: UserDto, index: number) => {
          const isLast = index === lecturers.length - 1;
          return (
            <span key={lecturer.pin}>
              <Link to={`/users/${lecturer.pin}`}>
                {lecturer.givenName + ' ' + lecturer.familyName}
              </Link>
              <span>{!isLast ? ', ' : ''}</span>
            </span>
          );
        }),
    },
    {
      key: 'classroom',
      title: t('event_list.columns.classroom'),
      dataIndex: 'classroom',
      width: '5%',
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'location',
      title: t('event_list.filter.location'),
      dataIndex: 'location',
      width: '10%',
      filterDropdown: (
        <Select
          allowClear
          value={filter.location}
          className='w-100'
          placeholder={t('event_list.filter.location')}
          options={Object.values(Location).map((location) => ({
            label: t(`event_list.locations.${location}`),
            value: location,
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({
              ...prevData,
              location: value,
            }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.location ? 1 : 0} />,
      render: (text) => (
        <span>{text ? t(`event_list.locations.${text}`) : ''}</span>
      ),
    },
  ];

  if (
    HasRoles([UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR], user)
  ) {
    columns.push({
      key: 'options',
      title: t('event_list.columns.options'),
      dataIndex: 'id',
      width: '25%',
      render: (id, record) => (
        <Flex gap={12}>
          <Tooltip title={t('event_list.QR.title')}>
            <Button onClick={() => getQRcode(record)}>
              <QrcodeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('check_ins.title')}>
            <Link
              to={`checked-in/${id}?${searchParams.toString()}`}
              state={record}
            >
              <Button>
                <TeamOutlined />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t('create_update_event.update_title')}>
            <Link to={`edit/${id}?${searchParams.toString()}`} state={record}>
              <Button>
                <EditOutlined />
              </Button>
            </Link>
          </Tooltip>
          <Popconfirm
            title={t('event_list.delete.title')}
            onConfirm={() => handleDeleteEvent(id)}
            okText={t('event_list.delete.yes')}
            cancelText={t('event_list.delete.no')}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      ),
    });
  }

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('event_list.title')} />
      <Flex gap={12} align='center'>
        {HasRoles(
          [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
          user
        ) ? (
          <>
            <NewButton
              onClick={() => navigate(`new?${searchParams.toString()}`)}
            />
            <ImportButton onClick={() => setIsImportModalOpen(true)} />
          </>
        ) : (
          <></>
        )}
      </Flex>

      <Table
        className='mt-1 mb-2'
        dataSource={events?.records}
        columns={columns}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.id}
        pagination={{
          hideOnSinglePage: true,
          total: events?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
        footer={null}
        width={720}
      >
        {checkInData ? <EventQRCode checkInData={checkInData} /> : <></>}
      </Modal>
      <ImportEvents
        isImportModalOpen={isImportModalOpen}
        setIsImportModalOpen={setIsImportModalOpen}
        setLoading={setLoading}
        setReload={setReload}
      />
    </Spin>
  );
};

export default Events;
