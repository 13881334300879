import { Flex, Select } from 'antd';
import {
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../../context/auth-context';

const { Option } = Select;

interface LecturerSelectProps {
  selectedLecturerPin?: string;
  className?: string;
  placeholder?: ReactNode;
  showSearch?: boolean;
  onChange?: (value: SetStateAction<any>) => void;
}

interface LecturerOption {
  value: string;
  label: ReactNode;
}

const LecturerSelect = (props: LecturerSelectProps) => {
  const { lecturers } = useContext(AuthContext);
  const [lecturerOptions, setLecturerOptions] = useState<LecturerOption[]>([]);

  useEffect(() => {
    if (lecturers) {
      const options = lecturers?.map((lecturer) => {
        const lecturerName = `${lecturer.givenName} ${lecturer.familyName}`;
        const lecturerEmail = lecturer.email ? `${lecturer.email}` : '';
        return {
          value: lecturer.pin,
          label: (
            <Flex vertical>
              <span className='lecturer-name'>{lecturerName}</span>
              <small className='text-gray lecturer-email'>
                <i>{lecturerEmail}</i>
              </small>
            </Flex>
          ),
        };
      });
      setLecturerOptions(options);
    }
  }, [lecturers]);

  const filterOption = (input: string, option: any) => {
    if (option) {
      const lecturerName =
        option?.children.props.children[0].props.children || '';
      return lecturerName.toLowerCase().includes(input.toLowerCase());
    }
    return false;
  };

  return (
    <Select
      allowClear
      value={props.selectedLecturerPin ? props.selectedLecturerPin : null}
      className={`${props.className} lecturer-select`}
      placeholder={props.placeholder}
      showSearch={props.showSearch}
      filterOption={filterOption}
      onChange={props.onChange}
    >
      {lecturerOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LecturerSelect;
