import { DownloadOutlined } from '@ant-design/icons';
import { Empty, Flex, Table, TablePaginationConfig, TableProps } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { PaginatedData } from '../../../../../types/dto/common.dto';
import {
  OtherExpenseAttachmentDto,
  OtherExpensesDto,
  WorksheetDto,
} from '../../../../../types/dto/worksheet.dto';

interface OtherExpensesProps {
  worksheet: WorksheetDto;
  setWsOtherExpenses: Dispatch<SetStateAction<OtherExpensesDto[]>>;
}

const OtherExpenses = (props: OtherExpensesProps) => {
  const { worksheet } = props;
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [otherExpenses, setOtherExpenses] =
    useState<PaginatedData<OtherExpensesDto[]>>();

  const fetchOtherExpenses = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getOtherExpenses({
      page: currentPage,
      pageSize: 10,
      worksheetId: worksheet.id,
    });
    setOtherExpenses(response);
    props.setWsOtherExpenses(response.records);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, worksheet.id]);

  useEffect(() => {
    fetchOtherExpenses();
    setReload(false);
  }, [fetchOtherExpenses, reload]);

  const downloadAttachment = async (binary: OtherExpenseAttachmentDto) => {
    setLoading(true);
    try {
      const binaryData = await Server.Worksheets.getOtherExpenseAttachment(
        binary.id
      );

      const blob = new Blob([binaryData], { type: 'application/octet-stream' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = binary.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {}

    setLoading(false);
  };

  const columns: TableProps<OtherExpensesDto>['columns'] = [
    {
      key: 'title',
      title: t('edit.expenses.expense_title'),
      dataIndex: 'title',
      width: '40%',
      render: (title) => <span>{title}</span>,
    },
    {
      key: 'attachments',
      title: t('edit.expenses.attachments'),
      dataIndex: 'binaries',
      width: '40%',
      render: (binaries: OtherExpenseAttachmentDto[]) =>
        binaries.map((binary) => (
          <Flex key={binary.id} gap={12}>
            <span>{binary.fileName}</span>
            <DownloadOutlined onClick={() => downloadAttachment(binary)} />
          </Flex>
        )),
    },
    {
      key: 'totalAmount',
      title: t('edit.expenses.total_amount'),
      dataIndex: 'totalAmount',
      width: '20%',
      align: 'right',
      render: (amount) => <span>{amount?.toFixed(2).replace('.', ',')} €</span>,
    },
  ];

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <div>
      <h4>{t('edit.expenses.title')}</h4>
      <Table
        rowKey={(val) => val.id}
        loading={loading}
        dataSource={otherExpenses?.records}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          total: otherExpenses?.totalCount,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </div>
  );
};

export default OtherExpenses;
