import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import {
  EventCheckInCodeDto,
  EventCheckInDto,
} from '../../types/dto/event-check-in.dto';

export class EventCheckIn {
  async getCheckInCode(eventId: string): Promise<EventCheckInCodeDto> {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/event-check-in`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getCheckIns(eventId: string): Promise<EventCheckInDto[]> {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/event-check-in/list`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
