import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { ColumnType } from '../../../../../../../types/enum/wl-column-type';

interface Props {
  columnType: ColumnType;
}

const MonthlyWorkLogPDFTableHeader = ({ columnType }: Props) => {
  const { t } = useTranslation('work-logs');

  const styles = StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderTop: '1px solid #000',
      borderBottom: '1px solid #000',
      padding: '4px 0',
      fontSize: 9,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      height: columnType === ColumnType.ABSENCE ? '60px' : '38px',
      backgroundColor: '#e6e6e6',
    },
    rowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      borderTop: '1px solid #fff',
      borderBottom: '1px solid #000',
      backgroundColor: '#36527c',
      color: '#fff',
      padding: '4px 0',
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    itemStyle: {
      fontFamily: 'Roboto',
      flex: 1,
      padding: '0 4px',
    },
  });

  return (
    <View style={styles.wrapper}>
      {columnType === ColumnType.PRESENCE ? (
        <>
          <View style={styles.rowCenter}>
            <Text>{t('view.columns.presence')}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.itemStyle}>{t('view.columns.day')}</Text>
            <Text style={styles.itemStyle}>{t('view.columns.startTime')}</Text>
            <Text style={styles.itemStyle}>{t('view.columns.endTime')}</Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.dailyWorkHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.dailyBreakMinutes')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.nightWorkHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.overtimeHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.holidayWorkHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.businessTripHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.redistributionHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.standbyHours')}
            </Text>
          </View>
        </>
      ) : (
        <>
          <View style={styles.rowCenter}>
            <Text>{t('view.columns.absence')}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.itemStyle}>{t('view.columns.day')}</Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.weeklyLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.holidayHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.annualLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.shortTermMedicalLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.longTermMedicalLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.parentalLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.paidLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.unpaidLeaveHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.requestedAbsenceHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.employerCausedAbsenceHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.strikeHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.lockoutHours')}
            </Text>
            <Text style={styles.itemStyle}>
              {t('view.columns.nonActiveHours')}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

export default MonthlyWorkLogPDFTableHeader;
