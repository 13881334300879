import { notification } from 'antd/es';
import axios from 'axios';

import i18n from '../i18n';

axios.interceptors.request.use((config) => {
  const authToken = window.localStorage.getItem('authToken');
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (exception) {
    const errResponse = exception.response;
    if (errResponse && errResponse.data) {
      const resConfig = errResponse.config;

      // This case handles missing token or expired token issue.
      // In that case user will be taken to login page and no notification is needed.
      if (
        (resConfig?.url?.endsWith('/v1/administrators') ||
          resConfig?.url?.endsWith('/v1/administrators/list')) &&
        errResponse.status === 403
      ) {
        return Promise.reject(exception);
      }

      const { message, statusCode } = errResponse.data;

      const messageTranslated = i18n.t(message, { ns: 'error' });

      notification.error({
        key: statusCode,
        message: messageTranslated,
        duration: 20,
      });
    } else if (exception.request) {
      notification.error({
        key: exception.request.statusCode,
        message: 'Network Error',
        description:
          'Connection to the API could not be established.\nIt may be temporarily unavailable or you may have issues with your internet connection.',
        duration: 20,
      });
    } else if (exception.message) {
      notification.error({
        key: exception.message,
        message: 'Network Error',
        description: exception.message,
        duration: 20,
      });
    }

    //return Promise.reject(exception);
    console.log(exception);
    return exception;
  }
);
