import './api';
import { ConfigProvider, ConfigProviderProps } from 'antd';
import en from 'antd/locale/en_GB';
import hr from 'antd/locale/hr_HR';
import 'dayjs/locale/hr';
import { useCallback, useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { themeConfig } from './context/antd-config/theme.config';
import { AuthContext } from './context/auth-context';
import i18n from './i18n';
import { AppRouter } from './router';

import './app.css';

type Locale = ConfigProviderProps['locale'];

function App() {
  const { language } = useContext(AuthContext);
  const [locale, setLocale] = useState<Locale>(en);

  const checkLanguage = useCallback(() => {
    i18n.changeLanguage(language);
    if (language === 'hr') {
      setLocale(hr);
    } else {
      setLocale(en);
    }
  }, [language]);

  useEffect(() => {
    checkLanguage();
  }, [checkLanguage]);

  return (
    <ConfigProvider locale={locale} theme={themeConfig}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
