import { Form, Input, Button, Grid, notification } from 'antd/es';
import { FormInstance } from 'antd/es/form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContext } from '../context/auth-context';

export function Login() {
  const { t } = useTranslation(['auth']);
  const { login, loginWithAaiEdu, setIntendedDestination, tokenLogin } =
    React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const form = React.useRef<FormInstance>(null);
  const email = React.useRef<any>();
  const password = React.useRef<any>();
  const sizing = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const intendedDestination = params.get('intendedDestination');
    const authToken = params.get('authToken');
    const errorMessage = params.get('e');

    if (errorMessage) {
      notification.error({
        key: errorMessage,
        message: t(`login.errors.${errorMessage}`),
      });
    }

    if (intendedDestination) {
      setIntendedDestination(intendedDestination);
    }

    if (authToken) {
      tokenLogin(authToken);
    }
  }, [params, location, setIntendedDestination, tokenLogin, t]);

  function aaiEduLogin() {
    try {
      setLoading(true);
      loginWithAaiEdu(location.state?.['intended-destination']);
    } catch (err: any) {
      form?.current?.resetFields(['password']);
      password?.current?.focus();
      return;
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    email?.current?.focus();
  }, []);

  async function handleLogin(values: any) {
    try {
      setLoading(true);
      try {
        await login({
          email: values.email,
          password: values.password,
        });
      } catch (err: any) {
        form?.current?.resetFields(['password']);
        password?.current?.focus();
        return;
      }

      const locationState = location.state as
        | { from?: { pathname?: string } }
        | undefined;
      const pathname = locationState?.from?.pathname ?? '/';
      navigate(pathname !== '/login' ? pathname : '/');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className='min-vh-100 d-flex justify-content-center align-items-center'>
        <div className='login'>
          <div className='text-center mb-2'>
            <img
              src={'/assets/aspira-uciliste-w.png'}
              alt='Logo'
              className='img-fluid mw-100'
            />
          </div>
          <Form
            ref={form}
            onFinish={handleLogin}
            className='text-white'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: '100%' }}
          >
            <Form.Item
              name='email'
              rules={[{ required: true, message: t('login.email.required') }]}
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Input placeholder={t('login.email.label')} ref={email} />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                { required: true, message: t('login.password.required') },
              ]}
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Input.Password
                placeholder={t('login.password.label')}
                ref={password}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Button
                block
                htmlType='submit'
                loading={loading}
                className='mt-1 logout-button'
              >
                {t('login.signIn')}
              </Button>
              <Button
                block
                className='mt-1 logout-button'
                loading={loading}
                style={{ width: '100%' }}
                onClick={aaiEduLogin}
              >
                {t('login.withAaiEdu')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
