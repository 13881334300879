import { StyleSheet, View } from '@react-pdf/renderer';

import {
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
} from '../../../../../types/dto/worksheet.dto';
import { ExpenseType } from '../../../../../types/enum/expense-type';
import { TableFooter } from './invoice-table-components/table-footer';
import { TableHeader } from './invoice-table-components/table-hader';
import { TableRow } from './invoice-table-components/table-row';
import { TableTopRow } from './invoice-table-components/table-top-row';

interface Props {
  worksheetEvents: WorksheetEventDto[];
  otherExpenses: OtherExpensesDto[];
  worksheet: WorksheetDto;
}

export function InvoiceTable({
  worksheetEvents,
  worksheet,
  otherExpenses,
}: Props) {
  const styles = StyleSheet.create({
    serviceContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

  return (
    <View style={styles.serviceContainer}>
      <TableHeader worksheet={worksheet} />
      {worksheetEvents.length > 0 ? (
        <>
          <TableTopRow expenseType={ExpenseType.EVENT} />
          <TableRow events={worksheetEvents} />
        </>
      ) : (
        <></>
      )}
      {otherExpenses.length > 0 ? (
        <>
          <TableTopRow expenseType={ExpenseType.OTHER} />
          <TableRow otherExpenses={otherExpenses} />
        </>
      ) : (
        <></>
      )}

      <TableFooter worksheet={worksheet} />
    </View>
  );
}
