import { Button, Flex, Form, Grid, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import MainTitle from '../../common/titles/main-title.component';

const ChangePassword = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const token = params.get('token');
  const { t } = useTranslation('settings');
  const sizing = Grid.useBreakpoint();

  const changePassword = async (values: any) => {
    if (token) {
      const response = await Server.User.setPassword({
        password: values.password,
        activationToken: token,
      });

      if (response.status === 200) {
        notification.success({
          message: t('password.activation.set_success'),
        });
        navigate('/login');
      }
    } else {
      notification.error({ message: t('password.activation.no_token') });
    }
  };

  return (
    <div className='min-vh-70 d-flex justify-content-center align-items-center'>
      <div className='login'>
        <Flex vertical align='center'>
          <MainTitle
            className='text-white'
            text={t('password.activation.title')}
          />

          <Form
            name='activate-account'
            className='mt-1'
            onFinish={changePassword}
          >
            <Form.Item
              name='password'
              rules={[
                { required: true, message: t('password.activation.required') },
              ]}
              validateTrigger='onBlur'
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Input.Password placeholder={t('password.activation.password')} />
            </Form.Item>
            <Form.Item
              name='confirm_password'
              dependencies={['password']}
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
              rules={[
                {
                  required: true,
                  message: t('password.activation.required'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('password.activation.no_match'))
                    );
                  },
                }),
              ]}
              validateTrigger='onBlur'
            >
              <Input.Password
                placeholder={t('password.activation.confirm_password')}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={sizing.xs === true ? undefined : { offset: 0 }}
            >
              <Button htmlType='submit' className='logout-button w-100'>
                {t('password.activation.submit')}
              </Button>
            </Form.Item>
          </Form>
        </Flex>
      </div>
    </div>
  );
};

export default ChangePassword;
