import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { WorksheetDto } from '../../../../../../types/dto/worksheet.dto';

interface Props {
  worksheet: WorksheetDto;
}

export function TableFooter({ worksheet }: Props) {
  const { t } = useTranslation('pdf');
  const styles = StyleSheet.create({
    serviceItem: {
      flex: 1,
      padding: '0 10px',
      paddingTop: '10px',
      fontWeight: 'bold',
    },
    rowEnd: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: '2px solid #e4e4e4',
    },
    fontSize12: { fontSize: 12 },
  });

  return (
    <View style={[styles.rowEnd, styles.serviceItem]}>
      <Text style={styles.fontSize12}>
        {t('table.footer.total').toUpperCase()}
      </Text>
      <Text style={styles.fontSize12}>
        {Number.parseFloat(worksheet.totalAmount?.toString())
          .toFixed(2)
          .replace('.', ',')}{' '}
        €
      </Text>
    </View>
  );
}
