export const setNavigationPath = (filter: any, dataSelection: any) => {
  const params = new URLSearchParams();

  Object.keys(dataSelection).forEach((key) => {
    if (dataSelection[key]) {
      params.set(key, dataSelection[key]);
    }
  });

  Object.keys(filter).forEach((key) => {
    if (Array.isArray(filter[key]) && filter[key].length === 0) {
      return;
    }
    if (filter[key]) {
      params.set(key, filter[key]);
    }
  });

  return params;
};
