import { Card, Collapse, Flex, Pagination, Table, TableProps, Tag } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { SearchDataSelection } from '../../../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../../../types/dto/common.dto';
import { ProgramDto } from '../../../../../types/dto/event.dto';
import { SubjectDto } from '../../../../../types/dto/subject.dto';
import { UserDto } from '../../../../../types/dto/user.dto';
import { ProgramLevel } from '../../../../../types/enum/program-level';
import { ProgramMode } from '../../../../../types/enum/program-mode';
import { getTagClassName } from '../../../../../utils/get-tag-class-name';

interface LecturerSubjectsProps {
  user: UserDto;
}

const LecturerSubjects = (props: LecturerSubjectsProps) => {
  const { language, currentAcademicYear } = useContext(AuthContext);
  const { t } = useTranslation(['user', 'programs']);
  const [loading, setLoading] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<PaginatedData<SubjectDto[]>>();
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: 1,
    pageSize: 10,
  });

  const fetchSubjects = useCallback(async () => {
    setLoading(true);
    const response = await Server.Subjects.getSubjects({
      page: dataSelection?.page,
      perPage: dataSelection?.pageSize,
      lecturerPin: props.user.pin,
      academicYear: currentAcademicYear,
    });

    setSubjects(response);
    setLoading(false);
  }, [currentAcademicYear, dataSelection, props.user.pin]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const columns: TableProps<ProgramDto>['columns'] = [
    {
      key: 'programName',
      title: t('programs:columns.program_name'),
      dataIndex: 'programName',
      width: '50%',
      render: (name, record) => (
        <span>
          {language === 'en'
            ? record.programNameEn ?? record.programName
            : record.programName}
        </span>
      ),
    },
    {
      key: 'level',
      title: t('programs:columns.level'),
      dataIndex: 'level',
      width: '10%',
      align: 'center',
      render: (level) => (
        <Tag className={getTagClassName(level as ProgramLevel)}>
          {t(`programs:levels.${level}`)}
        </Tag>
      ),
    },
    {
      key: 'mode',
      title: t('programs:columns.mode'),
      dataIndex: 'mode',
      width: '15%',
      align: 'center',
      render: (mode) => (
        <Tag className={getTagClassName(mode as ProgramMode)}>
          {t(`programs:modes.${mode}`)}
        </Tag>
      ),
    },
    {
      key: 'location',
      title: t('programs:columns.location'),
      dataIndex: 'location',
      width: '10%',
      align: 'center',
      render: (location) => <Tag>{t(`programs:locations.${location}`)}</Tag>,
    },
  ];

  return (
    <Card loading={loading}>
      {subjects && subjects.records.length > 0 ? (
        <>
          <Collapse
            className='mt-05'
            accordion
            destroyInactivePanel
            items={subjects.records.map((subject) => ({
              key: subject.id,
              label: (
                <span>
                  {language === 'en'
                    ? subject.subjectNameEn ?? subject.subjectName
                    : subject.subjectName}
                </span>
              ),
              children: (
                <Flex vertical>
                  <h4 className='mt-05 mb-05'>
                    {t('programs:subject_programs')}
                  </h4>
                  <Table
                    rowKey={(val) => val.id}
                    scroll={{ x: 800 }}
                    size='small'
                    dataSource={subject.programs}
                    pagination={{ hideOnSinglePage: true }}
                    columns={columns}
                  />
                </Flex>
              ),
            }))}
          />
          <Flex justify='end'>
            <Pagination
              hideOnSinglePage
              size='small'
              className='mt-05'
              current={dataSelection.page}
              total={subjects.totalCount}
              pageSize={dataSelection.pageSize}
              onChange={(page) =>
                setDataSelection((prevData) => ({ ...prevData, page: page }))
              }
              showSizeChanger={false}
            />
          </Flex>
        </>
      ) : (
        <span>{t('lecturer_subjects.no_subjects')}</span>
      )}
    </Card>
  );
};

export default LecturerSubjects;
