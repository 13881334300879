import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { ExpenseType } from '../../../../../../types/enum/expense-type';

interface Props {
  expenseType: ExpenseType;
}

export function TableTopRow(props: Props) {
  const { t } = useTranslation('pdf');
  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderTop: '1px solid #b5b3b3',
      borderBottom: '1px solid #b5b3b3',
      padding: '4px 0',
      fontSize: 10,
      fontWeight: 'bold',
    },
    itemStyle: { flex: 1, padding: '0 10px' },
    textAlignStart: { textAlign: 'left' },
    textAlignCenter: { textAlign: 'center' },
    textAlignEnd: { textAlign: 'right' },
  });
  return (
    <View style={styles.row}>
      <View style={[styles.itemStyle, styles.textAlignStart]}>
        <Text>
          {props.expenseType === ExpenseType.EVENT
            ? t('table.top_row.type')
            : t('table.top_row.other_expense')}
        </Text>
      </View>
      {props.expenseType === ExpenseType.EVENT ? (
        <View style={[styles.itemStyle, styles.textAlignCenter]}>
          <Text>{t('table.top_row.period')}</Text>
        </View>
      ) : (
        <></>
      )}
      <View style={[styles.itemStyle, styles.textAlignEnd]}>
        <Text>{t('table.top_row.amount')}</Text>
      </View>
    </View>
  );
}
