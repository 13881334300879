import { useState } from 'react';

import ViewMonthlyWorkLog from './view-monthly-work-log/view-monthly-work-log.component';

import MonthlyWorkLogsList from '.';

interface Props {
  employeePin?: string;
}

const WorkLogs = (props: Props) => {
  const [monthlyWorkLogId, setMonthlyWorkLogId] = useState<string>('');

  return monthlyWorkLogId ? (
    <ViewMonthlyWorkLog
      monthlyWorkLogId={monthlyWorkLogId}
      setMonthlyWorkLogId={setMonthlyWorkLogId}
    />
  ) : (
    <MonthlyWorkLogsList
      employeePin={props.employeePin}
      setMonthlyWorkLogId={setMonthlyWorkLogId}
    />
  );
};

export default WorkLogs;
