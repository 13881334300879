import { ReadOutlined, TeamOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  Input,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { AuthContext } from '../../../context/auth-context';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { ProgramDto } from '../../../types/dto/event.dto';
import { Location } from '../../../types/enum/location';
import { ProgramLevel } from '../../../types/enum/program-level';
import { ProgramMode } from '../../../types/enum/program-mode';
import { getTagClassName } from '../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../utils/navigation-params';
import FilterIcon from '../../common/icons/filter-icon.component';
import MainTitle from '../../common/titles/main-title.component';

const Programs = () => {
  const { language } = useContext(AuthContext);
  const { t } = useTranslation('programs');
  const [searchParams, setSearchParams] = useSearchParams();
  const [programs, setPrograms] = useState<PaginatedData<ProgramDto[]>>();
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
    search: searchParams.get('search') ?? '',
  });
  const [filter, setFilter] = useState<{
    level?: ProgramLevel;
    mode?: ProgramMode;
    location?: Location;
  }>({
    level: (searchParams.get('level') as ProgramLevel) ?? undefined,
    mode: (searchParams.get('mode') as ProgramMode) ?? undefined,
    location: (searchParams.get('location') as Location) ?? undefined,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPrograms = useCallback(async () => {
    setLoading(true);
    const response = await Server.Programs.getPrograms({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      search: dataSelection.search,
      level: filter.level,
      mode: filter.mode,
      location: filter.location,
    });
    setPrograms(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchPrograms();
    setSearchParams(setNavigationPath(filter, dataSelection));
  }, [dataSelection, fetchPrograms, filter, setSearchParams]);

  const columns: TableProps<ProgramDto>['columns'] = [
    {
      key: 'programName',
      title: t('columns.program_name'),
      dataIndex: 'programName',
      width: '40%',
      filterDropdown: () => (
        <Input
          placeholder={t('filter.search')}
          className='w-100'
          value={dataSelection.search}
          onChange={(event) => {
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
              search: event.target.value,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={dataSelection.search ? 1 : 0} />,
      render: (name, record) => (
        <span>
          {language === 'en'
            ? record.programNameEn ?? record.programName
            : record.programName}
        </span>
      ),
    },
    {
      key: 'level',
      title: t('columns.level'),
      dataIndex: 'level',
      width: '20%',
      filterDropdown: () => (
        <Select
          allowClear
          value={filter.level}
          placeholder={t('filter.select_level')}
          className='w-100'
          options={Object.values(ProgramLevel).map((level) => ({
            label: t(`levels.${level}`),
            value: level,
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({ ...prevData, level: value }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.level ? 1 : 0} />,
      render: (level) => (
        <Tag className={getTagClassName(level as ProgramLevel)}>
          {t(`levels.${level}`)}
        </Tag>
      ),
    },
    {
      key: 'mode',
      title: t('columns.mode'),
      dataIndex: 'mode',
      width: '20%',
      filterDropdown: () => (
        <Select
          allowClear
          value={filter.mode}
          placeholder={t('filter.select_mode')}
          className='w-100'
          options={Object.values(ProgramMode).map((mode) => ({
            label: t(`modes.${mode}`),
            value: mode,
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({ ...prevData, mode: value }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.mode ? 1 : 0} />,
      render: (mode) => (
        <Tag className={getTagClassName(mode as ProgramMode)}>
          {t(`modes.${mode}`)}
        </Tag>
      ),
    },
    {
      key: 'location',
      title: t('columns.location'),
      dataIndex: 'location',
      width: '10%',
      filterDropdown: () => (
        <Select
          allowClear
          value={filter.location}
          placeholder={t('filter.location')}
          className='w-100'
          options={Object.values(Location).map((location) => ({
            label: t(`locations.${location}`),
            value: location,
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({
              ...prevData,
              location: value,
            }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.location ? 1 : 0} />,
      render: (location) => <span>{t(`locations.${location}`)}</span>,
    },
    {
      key: 'details',
      title: t('columns.details'),
      dataIndex: 'id',
      width: '15%',
      render: (id) => (
        <Flex gap={12}>
          <Tooltip title={t('subjects.tooltip')}>
            <Link to={`subjects/${id}`}>
              <Button>
                <ReadOutlined />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t('students.tooltip')}>
            <Link to={`students/${id}`}>
              <Button>
                <TeamOutlined />
              </Button>
            </Link>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Flex align='center' gap={12}>
        <MainTitle text={t('programs_list.title')} />
      </Flex>
      <Table
        className='mb-2'
        columns={columns}
        dataSource={programs?.records}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.id}
        pagination={{
          hideOnSinglePage: true,
          current: dataSelection.page,
          pageSize: dataSelection.pageSize,
          total: programs?.totalCount,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default Programs;
