import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { GetSubjectsDto, SubjectDto } from '../../types/dto/subject.dto';

export class Subjects {
  async getSubjects(
    getSubjectsDto: GetSubjectsDto
  ): Promise<PaginatedData<SubjectDto[]>> {
    const params = new URLSearchParams();
    params.append('academicYear', getSubjectsDto.academicYear.toString());
    if (getSubjectsDto.page && getSubjectsDto.perPage) {
      params.append('page', getSubjectsDto.page.toString());
      params.append('perPage', getSubjectsDto.perPage.toString());
    }
    if (getSubjectsDto.subjectName) {
      params.append('subjectName', getSubjectsDto.subjectName);
    }
    if (getSubjectsDto.lecturerPin) {
      params.append('lecturerPin', getSubjectsDto.lecturerPin);
    }
    if (getSubjectsDto.studentPin) {
      params.append('studentPin', getSubjectsDto.studentPin);
    }
    if (getSubjectsDto.programId) {
      params.append('programId', getSubjectsDto.programId);
    }

    const response = await axios({
      method: 'GET',
      responseType: 'json',
      url: `${API_URL}/api/admin/subject`,
      params,
    });
    return response.data;
  }

  async getSubjectsFlat(): Promise<SubjectDto[]> {
    const response = await axios({
      method: 'GET',
      responseType: 'json',
      url: `${API_URL}/api/admin/subject/flat`,
    });
    return response.data;
  }
}
