import {
  Col,
  Empty,
  Flex,
  Input,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { SearchDataSelection } from '../../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../../types/dto/common.dto';
import { ProgramDto } from '../../../../types/dto/event.dto';
import { UserDto } from '../../../../types/dto/user.dto';
import { getTagClassName } from '../../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../../utils/navigation-params';
import BackButton from '../../../common/buttons/back-button.component';
import Subtitle from '../../../common/titles/subtitle.component';

const ProgramStudents = () => {
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('programs');
  const [program, setProgram] = useState<ProgramDto>();
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
    search: searchParams.get('search') ?? '',
  });
  const { currentAcademicYear } = useContext(AuthContext);
  const [enrolledStudents, setEnrolledStudents] =
    useState<PaginatedData<UserDto[]>>();
  const [selectedAcademicYear, setSelectedAcademicYear] =
    useState<number>(currentAcademicYear);
  const [academicYearOptions, setAcademicYearOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >();

  const fetchProgram = useCallback(async () => {
    if (params.programId) {
      setLoading(true);
      const response = await Server.Programs.getProgramById(params.programId);
      setProgram(response);
    }
  }, [params.programId]);

  const fetchAcademicYearOptions = useCallback(async () => {
    const academicYearResponse = await Server.Programs.getAcademicYearOptions(
      'studentProgram'
    );
    const options = academicYearResponse.map((o) => ({
      label: o.toString(),
      value: o,
    }));
    setAcademicYearOptions(options);
  }, []);

  const fetchEnrolledStudents = useCallback(async () => {
    if (program) {
      setLoading(true);
      const response = await Server.User.getEnrolledStudents(
        dataSelection.page,
        dataSelection.pageSize,
        program.id,
        selectedAcademicYear,
        dataSelection.search
      );

      setEnrolledStudents(response);
      setLoading(false);
    }
  }, [selectedAcademicYear, dataSelection, program]);

  useEffect(() => {
    fetchProgram();
    fetchAcademicYearOptions();
  }, [fetchAcademicYearOptions, fetchProgram]);

  useEffect(() => {
    fetchEnrolledStudents();
    setSearchParams(setNavigationPath({ selectedAcademicYear }, dataSelection));
  }, [
    selectedAcademicYear,
    dataSelection,
    fetchEnrolledStudents,
    setSearchParams,
  ]);

  const columns: TableProps<UserDto>['columns'] = [
    {
      key: 'pin',
      title: t('students.pin'),
      dataIndex: 'pin',
      width: '10%',
      render: (pin) => <Link to={`/users/${pin}`}>{pin}</Link>,
    },
    {
      key: 'hrEduUid',
      title: t('students.HrEduUid'),
      dataIndex: 'hrEduUid',
      width: '10%',
      render: (hrEduUid) => <span>{hrEduUid}</span>,
    },
    {
      key: 'givenName',
      title: t('students.given_name'),
      dataIndex: 'givenName',
      width: '25%',
      render: (givenName) => <span>{givenName}</span>,
    },
    {
      key: 'familyName',
      title: t('students.family_name'),
      dataIndex: 'familyName',
      width: '25%',
      render: (familyName) => <span>{familyName}</span>,
    },
    {
      key: 'email',
      title: t('students.email'),
      dataIndex: 'email',
      width: '30%',
      render: (email) => <span>{email}</span>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <BackButton href={`/programs/${location?.search}`} />
      <Flex vertical>
        <Flex gap={4} className='mobile-vertical'>
          <Subtitle text={t('students.title')} />
          {program ? (
            <i>
              <Subtitle text={program.programName} />
            </i>
          ) : (
            <></>
          )}
        </Flex>
        {program ? (
          <div>
            <Tag className={getTagClassName(program.level)}>
              {t(`levels.${program.level}`)}
            </Tag>
            <Tag className={getTagClassName(program.mode)}>
              {t(`modes.${program.mode}`)}
            </Tag>
            <Tag>{t(`locations.${program.location}`)}</Tag>
          </div>
        ) : (
          <></>
        )}
      </Flex>
      <Row gutter={[12, 12]} className='mt-1'>
        <Col xs={6} sm={6} md={6} lg={4} xl={2}>
          <Select
            value={selectedAcademicYear}
            options={academicYearOptions}
            onChange={(value) => setSelectedAcademicYear(value)}
          />
        </Col>
        <Col xs={18} sm={18} md={12} lg={8} xl={8}>
          <Input
            placeholder={t('filter.search')}
            value={dataSelection.search}
            onChange={(event) => {
              setDataSelection((prevVal) => ({
                ...prevVal,
                page: 1,
                search: event.target.value,
              }));
            }}
          />
        </Col>
      </Row>
      <Table
        className='mt-1'
        columns={columns}
        dataSource={enrolledStudents?.records}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.pin}
        pagination={{
          hideOnSinglePage: true,
          total: enrolledStudents?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default ProgramStudents;
