import { View, StyleSheet, Text } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  DailyWorkLogDto,
  MonthlyWorkLogDto,
} from '../../../../../../../types/dto/work-logs.dto';
import { ColumnType } from '../../../../../../../types/enum/wl-column-type';
import { DateUtils } from '../../../../../../../utils/date.utils';

interface Props {
  columnType: ColumnType;
  dailyWorkLog: DailyWorkLogDto;
  monthlyWorkLog: MonthlyWorkLogDto;
}

const MonthlyWorkLogPDFTableRow = ({
  columnType,
  dailyWorkLog,
  monthlyWorkLog,
}: Props) => {
  const { t } = useTranslation('work-logs');

  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderTop: '0.5px solid #D3D3D3',
      borderBottom: '0.5px solid #D3D3D3',
      padding: '4px 0',
      fontSize: 9,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    itemStyle: {
      fontFamily: 'Roboto',
      flex: 1,
      padding: '0 4px',
    },
  });
  return columnType === ColumnType.PRESENCE ? (
    <View style={styles.row}>
      <Text style={styles.itemStyle}>{`${t(
        `view.days.${DateUtils.dayOfWeekShortname(
          dailyWorkLog.day,
          monthlyWorkLog.month,
          monthlyWorkLog.year
        )}`
      )}, ${dailyWorkLog.day}.${monthlyWorkLog.month}.`}</Text>
      <Text style={styles.itemStyle}>
        {dailyWorkLog.startTime
          ? dayjs(dailyWorkLog.startTime, 'HH:mm').format('HH:mm')
          : ''}
      </Text>
      <Text style={styles.itemStyle}>
        {dailyWorkLog.endTime
          ? dayjs(dailyWorkLog.endTime, 'HH:mm').format('HH:mm')
          : ''}
      </Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.dailyWorkHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.dailyBreakMinutes}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.nightWorkHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.overtimeHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.holidayWorkHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.businessTripHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.redistributionHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.standbyHours}</Text>
    </View>
  ) : (
    <View style={styles.row}>
      <Text style={styles.itemStyle}>{`${t(
        `view.days.${DateUtils.dayOfWeekShortname(
          dailyWorkLog.day,
          monthlyWorkLog.month,
          monthlyWorkLog.year
        )}`
      )}, ${dailyWorkLog.day}.${monthlyWorkLog.month}.`}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.weeklyLeaveHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.holidayHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.annualLeaveHours}</Text>
      <Text style={styles.itemStyle}>
        {dailyWorkLog.shortTermMedicalLeaveHours}
      </Text>
      <Text style={styles.itemStyle}>
        {dailyWorkLog.longTermMedicalLeaveHours}
      </Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.parentalLeaveHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.paidLeaveHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.unpaidLeaveHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.requestedAbsenceHours}</Text>
      <Text style={styles.itemStyle}>
        {dailyWorkLog.employerCausedAbsenceHours}
      </Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.strikeHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.lockoutHours}</Text>
      <Text style={styles.itemStyle}>{dailyWorkLog.nonActiveHours}</Text>
    </View>
  );
};

export default MonthlyWorkLogPDFTableRow;
