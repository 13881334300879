import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { PartnerDto } from '../../../../../types/dto/partner.dto';
import { PartnerType } from '../../../../../types/enum/partner-type';
import Subtitle from '../../../../common/titles/subtitle.component';
import { formItemLayoutLG } from '../../../../layout/form/form-item.layout';

export function EditPartner() {
  const { t } = useTranslation('partner');
  const [form] = useForm<PartnerDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const { userPin } = useParams<{ userPin: string }>();
  const [partner, setPartner] = useState<PartnerDto>();
  const [partnerToAssign, setPartnerToAssign] = useState<PartnerDto>();
  const [showIntl, setShowIntl] = useState<boolean>(false);

  const fetchPartner = useCallback(async () => {
    if (userPin) {
      setLoading(true);
      const __partner = await Server.Partner.getPartner(userPin);
      setPartner(__partner);
      if (__partner.intlBankAccountNr) {
        setShowIntl(true);
      }
      form.setFieldsValue(__partner);
      setLoading(false);
    }
  }, [userPin, form]);

  useEffect(() => {
    fetchPartner();
  }, [fetchPartner]);

  const submitPartner = async (vals: PartnerDto) => {
    setLoading(true);
    if (userPin && (!partner || partner.pin !== vals.pin)) {
      const assignResponse = await Server.Partner.assignPartner(userPin, vals);
      if (assignResponse.status === 200) {
        notification.success({ message: t('assign.success') });
      }
    } else if (userPin && partner) {
      const updateResponse = await Server.Partner.updatePartner(
        partner.pin,
        vals
      );
      if (updateResponse.status === 200) {
        notification.success({ message: t('edit.success') });
      }
    }

    await fetchPartner();

    setLoading(false);
  };

  const getPartnerByPin = async (pin: string) => {
    const response = await Server.Partner.getPartnerByPin(pin);
    if (response) {
      setPartnerToAssign(response);
      if (response.intlBankAccountNr) {
        setShowIntl(true);
      }
      form.setFieldsValue(response);
    }
  };

  return (
    <Card loading={loading}>
      <Flex gap={18} align='center'>
        <Subtitle text={partner ? t('edit.title') : t('new.title')} />
      </Flex>
      <Form form={form} onFinish={submitPartner} {...formItemLayoutLG}>
        <Form.Item name={'pin'} label={t('form.pin')}>
          <Input
            onChange={(e) => {
              if (e.target.value.length < 11 && partnerToAssign) {
                setPartnerToAssign(undefined);
                form.resetFields();
              }
              if (e.target.value.length === 11) {
                getPartnerByPin(e.target.value);
              }
            }}
          />
        </Form.Item>
        <Form.Item name={'type'} label={t('form.type')}>
          <Select
            options={Object.values(PartnerType).map((type) => {
              return { key: type, value: type, label: t(`types.${type}`) };
            })}
          />
        </Form.Item>
        <Form.Item name={'name'} label={t('form.name')}>
          <Input />
        </Form.Item>
        <Form.Item name={'iban'} label={t('form.iban')}>
          <Input />
        </Form.Item>
        <Form.Item name={'bankName'} label={t('form.bankName')}>
          <Input />
        </Form.Item>
        <Form.Item name={'address'} label={t('form.address')}>
          <Input />
        </Form.Item>
        <Form.Item name={'town'} label={t('form.town')}>
          <Input />
        </Form.Item>
        <Form.Item name={'postCode'} label={t('form.postCode')}>
          <Input />
        </Form.Item>
        <Form.Item name={'country'} label={t('form.country')}>
          <Input />
        </Form.Item>
        <Form.Item name={'phone'} label={t('form.phone')}>
          <Input />
        </Form.Item>
        <Form.Item name={'email'} label={t('form.email')}>
          <Input />
        </Form.Item>
        <Form.Item name={'website'} label={t('form.website')}>
          <Input />
        </Form.Item>
        <Flex align='center' gap={8}>
          <h4>{t('new.title_intl')}</h4>
          <Switch checked={showIntl} onChange={() => setShowIntl(!showIntl)} />
        </Flex>
        {showIntl ? (
          <>
            <Form.Item
              label={t('form.intlBankAccountNr')}
              name='intlBankAccountNr'
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankSwift')} name='intlBankSwift'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankAdress')} name='intlBankAdress'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankTown')} name='intlBankTown'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankZip')} name='intlBankZip'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankCountry')} name='intlBankCountry'>
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Flex justify='flex-end'>
          <Form.Item>
            <Button type='primary' ghost onClick={form.submit}>
              {t('common:save')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
}
