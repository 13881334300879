import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { ProgramDto } from '../../types/dto/event.dto';
import {
  AssignProgramsToCoordinatorDto,
  GetProgramsDto,
} from '../../types/dto/program.dto';

export class Programs {
  async getPrograms(
    getProgramsDto?: GetProgramsDto
  ): Promise<PaginatedData<ProgramDto[]>> {
    const params = new URLSearchParams();
    if (getProgramsDto) {
      params.append('page', getProgramsDto.page.toString());
      params.append('pageSize', getProgramsDto.pageSize.toString());

      if (getProgramsDto.search) {
        params.append('search', getProgramsDto.search);
      }
      if (getProgramsDto.level) {
        params.append('level', getProgramsDto.level);
      }
      if (getProgramsDto.mode) {
        params.append('mode', getProgramsDto.mode);
      }
      if (getProgramsDto.location) {
        params.append('location', getProgramsDto.location);
      }
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/programs`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getProgramsFlat(): Promise<ProgramDto[]> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/programs/flat`,
      responseType: 'json',
    });

    return response.data;
  }

  async getProgramById(programId: string): Promise<ProgramDto> {
    const params = new URLSearchParams();
    params.append('programId', programId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/programs/id`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getStudentPrograms(
    studentPin: string,
    academicYear: number
  ): Promise<ProgramDto[]> {
    const params = new URLSearchParams();
    params.append('studentPin', studentPin);
    params.append('academicYear', academicYear.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/programs/student-programs`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async assignProgramsToCoordinator(
    assignProgramsToCoordinatorDto: AssignProgramsToCoordinatorDto
  ) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/programs/assign-coordinator`,
      responseType: 'json',
      data: assignProgramsToCoordinatorDto,
    });
  }

  async removeProgramCoordinator(coordinatorPin: string, programId: string) {
    const params = new URLSearchParams();
    params.append('coordinatorPin', coordinatorPin);
    params.append('programId', programId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/programs/delete-coordinator`,
      responseType: 'json',
      params,
    });
  }

  async getAcademicYearOptions(tableName: string): Promise<number[]> {
    const params = new URLSearchParams();
    params.append('tableName', tableName);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/programs/academic-years`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
