import { UserDto } from '../types/dto/user.dto';
import { UserRoles } from '../types/enum/user-roles';

export const HasRoles = (roles: UserRoles[], user?: UserDto) => {
  return user?.roles?.some((role) => roles.includes(role));
};

export const IsAdmin = (user: UserDto) => {
  return HasRoles([UserRoles.ADMINISTRATOR], user);
};
