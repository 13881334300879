import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import {
  DailyWorkLogDto,
  GetMonthlyWorkLogsDto,
  MonthlyWorkLogDto,
  UpdateDailyWorkLogsDto,
} from '../../types/dto/work-logs.dto';

export class WorkLogs {
  async getMonthlyWorkLogs(
    getMonthlyWorkLogsDto: GetMonthlyWorkLogsDto
  ): Promise<PaginatedData<MonthlyWorkLogDto[]>> {
    const { page, pageSize, month, year, employeePin } = getMonthlyWorkLogsDto;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('employeePin', employeePin.toString());

    if (month) {
      params.append('month', month.toString());
    }
    if (year) {
      params.append('year', year.toString());
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/monthly-work-log`,
      responseType: 'json',
      params,
    });

    return response.data as PaginatedData<MonthlyWorkLogDto[]>;
  }

  async getMonthlyWorkLogById(
    monthlyWorkLogId: string
  ): Promise<MonthlyWorkLogDto> {
    const params = new URLSearchParams();
    params.append('monthlyWorkLogId', monthlyWorkLogId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/monthly-work-log/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createMonthlyWorkLog(
    period: { month: number; year: number },
    employeePin: string
  ) {
    const params = new URLSearchParams();
    params.append('employeePin', employeePin);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/monthly-work-log`,
      responseType: 'json',
      data: period,
      params,
    });
  }

  async getDailyWorkLogs(monthlyWorkLogId: string): Promise<DailyWorkLogDto[]> {
    const params = new URLSearchParams();
    params.append('monthlyWorkLogId', monthlyWorkLogId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/daily-work-log`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async updateDailyWorkLogs(updateData: UpdateDailyWorkLogsDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/daily-work-log`,
      responseType: 'json',
      data: updateData,
    });

    return response.data;
  }

  async getDailyWorkLogById(dailyWorkLogId: string): Promise<DailyWorkLogDto> {
    const params = new URLSearchParams();
    params.append('dailyWorkLogId', dailyWorkLogId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/daily-work-log/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async deleteMonthlyWorkLog(monthlyWorkLogId: string) {
    const params = new URLSearchParams();
    params.append('monthlyWorkLogId', monthlyWorkLogId);

    await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/monthly-work-log`,
      responseType: 'json',
      params,
    });
  }
}
