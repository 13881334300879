import { ImportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  onClick: MouseEventHandler<HTMLElement>;
}

const ImportButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className={props.className}
      onClick={props.onClick}
      icon={<ImportOutlined />}
    >
      {t('common:import')}
    </Button>
  );
};

export default ImportButton;
