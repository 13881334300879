import { Layout } from 'antd';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { RoutesConstants } from '../../router/constants/routes-constants';
import HeaderLogo from './header/header-logo.component';
import Sidebar from './sidebar/sidebar.component';

export function AppLayout() {
  const { intendedDestination, setIntendedDestination, user } =
    useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!intendedDestination) {
      return;
    }

    setIntendedDestination(undefined);
    navigate(intendedDestination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intendedDestination, navigate]);

  return (
    <Layout>
      <HeaderLogo />
      <Sidebar />
      <div className='container py-5 px-1'>
        <Routes>
          {RoutesConstants(user).map((route, index) => {
            if (route.visibleToUser) {
              if (route.subroutes) {
                return (
                  <Route key={index} path={route.path} element={route.element}>
                    {route.subroutes.map((subroute, i) => {
                      if (subroute.visibleToUser) {
                        return (
                          <Route
                            key={i}
                            path={subroute.path}
                            element={subroute.element}
                          />
                        );
                      } else {
                        return undefined;
                      }
                    })}
                  </Route>
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              }
            } else {
              return undefined;
            }
          })}
          <Route path='*' element={<Navigate to={'/events'} />} />
        </Routes>
      </div>
    </Layout>
  );
}
